import {AnyObject} from '@v2/types/general';
import {isFunction, isNil} from './index';

export function hasProperty(
  item: Record<PropertyKey, unknown>,
  property: PropertyKey
): property is keyof typeof item;
export function hasProperty<TItem, TPropName extends PropertyKey>(
  item: TItem,
  property: TPropName
): item is TItem & Record<TPropName, unknown>;
/**
 * **Careful**: Defining a PropValue without passing a type guard makes a type assertion,
 * which overrides the compiler readings and may cause bugs down the future.
 * Use it only if you understand the implications of it.
 */
export function hasProperty<TItem, TPropName extends PropertyKey, TPropValue>(
  item: TItem,
  property: TPropName
): item is TItem & Record<TPropName, TPropValue>;
export function hasProperty<TItem, TPropName extends PropertyKey, TPropValue>(
  item: TItem,
  property: TPropName,
  typeGuard: (value: unknown) => value is TPropValue
): item is TItem & Record<TPropName, TPropValue>;

export function hasProperty<TItem, TPropName extends PropertyKey, TPropValue>(
  item: TItem,
  property: TPropName,
  typeGuard?: (value: unknown) => value is TPropValue
): item is TItem & Record<TPropName, TPropValue> {
  if (isNil(item)) return false;

  try {
    const _item = item as Record<string, unknown>;
    if (property in _item) {
      return typeGuard
        ? typeGuard(_item[property as keyof typeof _item])
        : true;
    }
    return false;
  } catch {
    return false;
  }
}

export function get<Property extends PropertyKey>(
  prop: Property
): <T extends Record<Property, unknown>>(
  item: T
) => Property extends keyof T ? T[Property] : undefined;
export function get<Property extends keyof T, T extends AnyObject>(
  prop: Property,
  item: T
): T[Property];
export function get<Property extends keyof T, T extends AnyObject>(
  prop: Property,
  item?: T
) {
  if (arguments.length === 1) return (item: T) => get(prop, item);
  return item?.[prop];
}

/**
 * Safely traverse a nested path of properties of an object.
 * If any of the nested properties do not exist on the object, then returns undefined.
 * @param {PropertyKey[]} props path of nested properties to traverse
 * @param object the object to dig into
 */
export function dig(object: AnyObject, props: string[]): unknown | undefined {
  return props.reduce((memo, prop) => memo?.[prop], object);
}

export function pick<Key extends string>(
  keys: Key[]
): <T extends {[key in Key]: unknown}>(item?: T) => {[K in Key]: T[K]};
export function pick<Key extends keyof T, T extends AnyObject>(
  keys: Key[],
  item?: T
): {[K in Key]: T[K]};
export function pick<
  Key extends string,
  T extends {[key in Key]: unknown},
  Z extends T,
>(
  keys: Key[],
  item?: T
): {[K in Key]: T[K]} | ((item?: Z) => {[K in Key]: Z[K]}) {
  if (arguments.length === 1) return (item?: Z) => pick(keys, item);

  if (!item) {
    throw new Error('Invalid value for item: undefined');
  }

  // @ts-expect-error Object will be built in the for loop
  const response: Pick<T, Key> = {};

  for (const key of keys) {
    response[key] = item[key];
  }

  return response;
}

export function omit<
  T extends AnyObject,
  Key extends Extract<keyof T, string | number>,
>(original: T, keys: Key[]): Omit<T, Key> {
  // @ts-expect-error Object will be built in the for loop
  const response: {[K in keyof T]: T[K]} = {};
  const allKeys = Object.keys(original) as Array<keyof T>;
  const excludeSet = new Set(keys.map(String));
  for (const key of allKeys) {
    if (!excludeSet.has(key as string)) {
      response[key] = original[key];
    }
  }
  return response;
}

export const objectKeys = Object.keys as <T extends AnyObject>(
  value: T
) => (keyof T)[];
export const objectEntries = Object.entries as <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends Record<PropertyKey, any>,
>(
  value: T
) => Array<
  {
    [Key in keyof Required<T>]: [Key, T[Key]];
  }[keyof T]
>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ComparisonObject<T = any> = {
  [Key in keyof T]?: T[Key] | ((value: T[Key]) => boolean);
};

/**
 *
 * @param details
 */
export function compare<U>(
  details: ComparisonObject<U>
): <T extends {[key in keyof U]: unknown}>(item?: T) => boolean;
export function compare<U extends T, T>(
  details: ComparisonObject<U>,
  item?: T
): boolean;
export function compare<
  U extends Z,
  T extends Record<keyof U, unknown>,
  Z extends T,
>(details: ComparisonObject<U>, item?: T): boolean | ((item?: Z) => boolean) {
  if (arguments.length === 1) return (item?: Z) => compare(details, item);
  return objectEntries(details).every(entry => {
    const [key, comparison] = entry;
    const currentValue = item?.[key];
    return isFunction(comparison)
      ? comparison(currentValue)
      : Object.is(currentValue, comparison);
  });
}

export const referenceId = (() => {
  let currentId = 0;
  const map = new WeakMap();

  return (object: object) => {
    if (!map.has(object)) {
      map.set(object, ++currentId);
    }

    return map.get(object);
  };
})();
